@tailwind base;
@tailwind components;
@tailwind utilities;

.gallery {
  @apply grid grid-cols-2 lg:grid-cols-3 lg:grid-cols-4 gap-4;
}

.gallery-item {
  @apply aspect-square max-w-full;
}
