@tailwind base;
@tailwind components;
@tailwind utilities;

.stepper {
  @apply flex items-center flex-row lg:justify-between gap-2 lg:gap-5 lg:w-[150px];
}

.stepper-value {
  @apply text-sm lg:text-lg;
}
