@tailwind base;
@tailwind components;
@tailwind utilities;

.loading-screen {
  @apply h-[50svh] inset-0 flex items-center justify-center z-50;
}

.loading-animation {
  @apply w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-gray-200;
}
