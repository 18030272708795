@tailwind base;
@tailwind components;
@tailwind utilities;

.app {
  @apply bg-white pt-[100px] relative overflow-x-hidden overscroll-contain min-h-screen flex flex-col justify-between;
}

.home {
  @apply w-screen min-h-[60vh] max-h-fit;
}

.column {
  @apply h-fit flex w-full flex-col gap-4
}

.page-title {
  @apply font-sans font-bold text-[30px] lg:text-[50px] leading-[39px] lg:leading-[59px] my-[15px] lg:my-[30px] text-center;
}

/* Product Page */
.product {
  @apply w-full lg:w-[1140px] grid lg:grid-cols-2 grid-cols-1 gap-6 items-center min-h-[400px] my-10;
}

.product-body {
  @apply flex flex-col h-full justify-start lg:justify-between px-[15px] lg:px-0 mt-10 lg:mt-0;
}

.product-price {
  @apply font-bold text-2xl mt-8;
}

.add-to-cart-button {
  @apply flex flex-row w-fit text-white items-center justify-center rounded-md py-2 px-4 mt-4 gap-2;
}

.product-not-available-label {
  @apply mt-4 text-sm italic;
}

/* Checkout Page */

.checkout-product-list {
  @apply w-full lg:w-[1140px] py-[30px] lg:py-[40px] flex flex-col gap-[20px];
}

.checkout-product-list-header-row {
  @apply grid grid-cols-4 lg:grid-cols-5 gap-2 lg:gap-4 items-center justify-center lg:text-lg;
}

.checkout-product-list-item {
  @apply grid grid-cols-4 lg:grid-cols-5 gap-2 lg:gap-4 items-center;
}

.checkout-product-list-item-image {
  @apply w-[84px] aspect-square object-cover rounded-[10px];
}

.checkout-product-list-item-title {
  @apply font-bold lg:text-lg;
}

.checkout-product-list-item-unit-price {
  @apply lg:text-lg;
}

.checkout-product-list-item-total {
  @apply flex justify-start font-bold items-center lg:text-lg;
}

.checkout-contact-summary-container {
  @apply lg:w-[1140px] w-full flex flex-col lg:flex-row gap-10 lg:gap-[70px] justify-between;
}

.checkout-user-input-form {
  @apply flex flex-col gap-10 w-full lg:w-1/2;
}

.checkout-user-input-form-title {
  @apply font-bold text-[20px];
}

.checkout-v-divider {
  @apply h-full bg-gray-400 w-[1px];
}

.checkout-h-divider {
  @apply border-l border-gray-300 h-full min-h-[1px] col-span-1;
}

.checkout-summary {
  @apply flex flex-col gap-10 w-full lg:w-1/2;
}

.checkout-summary-card {
  @apply w-full lg:w-[500px] lg:h-[290px] p-4 lg:p-8 grid grid-cols-2 gap-y-[10px] bg-white drop-shadow-xl rounded-[10px];
}

.checkout-summary-title {
  @aplpy mb-[15px];
}

.checkout-summary-title-bold {
  @apply font-bold lg:mb-[40px] mt-[15px];
}

.checkout-terms-check {
  @apply flex items-center flex-col gap-[32px];
}

.checkout-terms-check-label {
  @apply flex flex-row gap-5 text-sm;
}

.checkout-terms-check-input {
  @apply size-10 mt-1;
}

.checkout-terms-error {
  @apply text-red-700 text-[14px];
}

.checkout {
  @apply bg-[#F7FAFD] flex flex-col gap-10 pb-20 px-3 lg:px-0;
}

.thank-you-section {
  @apply w-full lg:w-[1140px] py-5 flex flex-col text-center pt-20 gap-[30px] px-[15px] lg:px-0;
}

.thank-you-title {
  @apply font-bold text-3xl lg:text-5xl;
}

.order-summary-title {
  @apply font-bold text-lg lg:text-2xl;
}

.order-summary-container {
  @apply grid grid-cols-1 lg:grid-cols-5 gap-4;
}

.order-summary {
  @apply flex flex-col gap-3 col-span-3;
}

.order-summary-row {
  @apply grid grid-cols-4 gap-2 lg:gap-4 items-center text-start;
}

.order-item-image {
  @apply w-[84px] aspect-square object-cover rounded-[10px];
}

.otder-item-title {
  @apply font-bold lg:text-lg line-clamp-3 overflow-ellipsis overflow-hidden;
}

.order-item-total {
  @apply flex justify-start font-bold items-center lg:text-lg;
}

.order-summary-price-breakdown-container {
  @apply flex justify-end;
}

.order-summary-price-breakdown {
  @apply grid grid-cols-2 w-1/2 gap-2 text-start;
}

.order-summary-price-breakdown-total {
  @apply font-bold lg:text-lg;
}

.order-summary-contact-details {
  @apply flex flex-row gap-8 lg:ml-4 col-span-2 justify-center;
}

.order-summary-contact-details-inner {
  @apply flex flex-col gap-2 lg:text-start;
}

.order-summary-contact-details-title {
  @apply font-bold text-lg lg:text-xl my-3 lg:mt-0;
}

/* Typography */

.h1-title {
  @apply lg:text-[50px] lg:leading-[59px] text-[30px] leading-[39px] font-bold mb-[15px] lg:mb-[30px] text-center;
}

.subtitle {
  @apply lg:text-[34px] lg:leading-[44px] text-[24px] leading-[34px] font-normal mb-[15px] lg:mb-[30px] text-center;
}

.h2-title {
  @apply lg:text-[42px] lg:leading-[54px] text-[32px] leading-[42px] font-bold mb-[15px] lg:mb-[20px] text-center;
}

.h3-title {
  @apply lg:text-[34px] lg:leading-[44px] text-[24px] leading-[32px] font-bold mb-[15px] lg:mb-[20px] text-center;
}

.h4-title {
  @apply lg:text-[22px] lg:leading-[32px] text-[18px] leading-[28px] font-bold mb-[15px] lg:mb-[20px] text-center;
}

.h5-title {
  @apply lg:text-[18px] lg:leading-[28px] text-[16px] leading-[26px] font-bold mb-[15px] lg:mb-[20px] text-center;
}

.paragraph {
  @apply lg:text-[16px] lg:leading-[26px] text-[16px] leading-[26px];
}

.paragraph-sm {
  @apply lg:text-[14px] lg:leading-[24px] text-[14px] leading-[24px];
}
