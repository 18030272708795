@tailwind base;
@tailwind components;
@tailwind utilities;

/* ------------------ Image ------------------ */
.image {
  @apply z-10 rounded-md w-full h-full aspect-auto object-cover cursor-pointer transition-transform;
}

.image-fullscreen-overlay {
  @apply fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-50;
}

.image-fullscreen-holder {
  @apply relative w-screen lg:w-1/2 flex flex-col items-center;
}

.image-expanded {
  @apply w-full lg:w-full h-auto rounded-md object-cover;
}
