@tailwind base;
@tailwind components;
@tailwind utilities;

/* -------------------- Header -------------------- */
.header {
  @apply fixed overflow-hidden lg:overflow-visible ease-in-out duration-700 top-0 z-50;
}
.header-inner {
  @apply relative w-screen h-[100px] flex items-center justify-center px-[15px] lg:px-0;
}
.header-inner-container {
  @apply lg:w-[1140px] w-full h-[60px] flex items-center justify-between;
}
.header-inner-logo {
  @apply h-[60px] max-w-fit;
}
.header-inner-navigation {
  @apply flex flex-row-reverse lg:flex-row gap-[15px] justify-center items-center w-auto;
}
.nav-item {
  @apply h4-title flex flex-col-reverse items-end gap-4 relative transform transition;
}
.nav-item-a {
  @apply absolute top-0 left-0 w-full lg:h-full cursor-pointer z-30;
}
.header-logo{
  @apply h-full
}
/* -------------------- Hamburger -------------------- */
.menu-toggle-holder {
  @apply h-[60px] w-[60px] flex items-center justify-center;
}
.menu-toggle {
  @apply w-[30px] h-fit relative focus:outline-none;
}
.menu-toggle-upper {
  @apply absolute rounded-full h-1 w-full transform transition duration-500 ease-in-out;
}
.menu-toggle-middle {
  @apply absolute rounded-full h-1 w-full transform transition duration-500 ease-in-out;
}
.menu-toggle-lower {
  @apply absolute rounded-full h-1 w-full transform transition duration-500 ease-in-out;
}

/* -------------------- Mobile Menu modal -------------------- */
.mobile-menu-modal {
  @apply relative w-screen;
}
.mobile-menu-modal-inner {
  @apply fixed h-full overflow-scroll flex flex-col transform duration-500 ease-in-out gap-6 pt-[5%] lg:pt-[15%] items-end px-[30px] pb-[30vh];
}
.submenu {
  @apply h3-title flex lg:max-w-max lg:min-w-max flex-col gap-2 lg:gap-0 lg:items-start items-end lg:absolute lg:left-0 lg:top-[33px] lg:pt-3 p-2 rounded-md lg:rounded-b-md text-right lg:shadow-xl;
}

/* -------------------- Font styles -------------------- */
.h4-title {
  @apply font-sans font-bold text-[27px] lg:text-[16px] leading-[32px] lg:leading-[33px] uppercase;
}
.h3-title {
  @apply font-sans font-bold text-[24px] lg:text-[14px] leading-[32px] lg:leading-[26px] uppercase;
}
