@tailwind base;
@tailwind components;
@tailwind utilities;

.cart {
  @apply rounded-full size-16 bg-gray-900 flex items-center justify-center cursor-pointer lg:hover:size-20 fixed bottom-[5%] right-[5%] z-20 ease-in-out duration-300;
}

.cart-counter {
  @apply size-6 text-center text-[12px] lg:group-hover:text-[16px] rounded-full absolute top-0 right-0 text-white lg:group-hover:size-8 ease-in-out duration-300 p-1;
}
