@tailwind base;
@tailwind components;
@tailwind utilities;

/* ------------------ Button Small ------------------ */
.button-sm {
  @apply text-white w-fit rounded-lg font-bold text-base;
}
.button-sm-inner {
  @apply w-full h-fit px-5 lg:px-6 lg:px-6 py-1 transition-all duration-300 rounded-lg;
}

/* ------------------ Button Medium ------------------ */
.button-md {
  @apply text-white w-fit rounded-lg font-bold text-base lg:text-lg lg:text-lg;
}
.button-md-inner {
  @apply w-full h-fit px-6 lg:px-8 lg:px-8 py-1.5 lg:py-2 lg:py-2 transition-all duration-300 rounded-lg;
}

/* ------------------ Button Large ------------------ */
.button-lg {
  @apply text-white w-fit rounded-lg font-bold text-lg lg:text-xl lg:text-xl;
}
.button-lg-inner {
  @apply w-full h-fit px-10 lg:px-12 lg:px-12 py-2 lg:py-3 lg:py-3 transition-all duration-300 rounded-lg;
}
