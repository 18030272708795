.card-title,
.blog-card-title,
.product-card-title,
.product-card-sm-title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (min-width: 320px) {
  .product-card-description {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media (min-width: 768px) {
  .product-card-description {
    -webkit-line-clamp: 2;
  }
}

@media (min-width: 1024px) {
  .product-card-description {
    -webkit-line-clamp: 3;
  }
}