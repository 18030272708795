@tailwind base;
@tailwind components;
@tailwind utilities;

.row {
  @apply w-full h-fit flex justify-center;
}

.row-container {
  @apply w-full h-fit lg:w-[1140px] flex flex-col flex-wrap lg:flex-row lg:items-start justify-between gap-[30px] lg:gap-[40px] px-3 lg:px-0 py-[30px] lg:py-[40px];
}
