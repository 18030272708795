@tailwind base;
@tailwind components;
@tailwind utilities;

.carousel-image {
  @apply  w-full lg:w-[1140px] h-full object-cover
}
/* .slide{
  @apply h-[160px] lg:h-[400px]
} */
.carousel-root{
  @apply -mx-3 lg:-mx-0 -my-[30px] lg:-my-[40px]
}