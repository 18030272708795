@tailwind base;
@tailwind components;
@tailwind utilities;

.filter {
  @apply w-full flex flex-row items-start gap-4;
}

.filted-drop-down {
  @apply appearance-none rounded-none text-[16px] font-sans font-bold border-black border-b-[1px] p-3 cursor-pointer;
}

.card {
  @apply bg-white shadow-md w-[calc((100%-1rem)/2)] lg:w-[calc((100%-2*1.5rem)/3)] h-[186px] lg:h-[220px] lg:h-[280px] rounded-[8px] flex flex-col relative transition-all duration-300 text-center flex-none;
}

.blog-card {
  @apply bg-white shadow-md w-[calc((100%-1rem)/2)] lg:w-[calc((100%-2*1.5rem)/3)] h-[234px] lg:h-[300px] rounded-[8px] flex flex-col relative transition-all duration-300 text-left flex-none;
}

.product-card {
  @apply bg-white shadow-md w-full lg:w-[calc((100%-1rem)/2)] h-[140px] lg:h-[486px] lg:h-[486px] flex flex-row lg:flex-col rounded-[8px] relative transition-all duration-300 text-left flex-none;
}

.card-link {
  @apply w-full h-full absolute top-0 left-0 z-10;
}

.card-image {
  @apply w-full h-2/3 rounded-t-[8px] transition-all duration-300 mb-1 lg:mb-2 object-cover;
}

.product-card-image {
  @apply w-2/5 lg:w-full h-full lg:h-2/3 lg:rounded-t-[8px] rounded-l-[8px] lg:rounded-b-none transition-all duration-300 object-cover;
}

.card-title {
  @apply text-[16px] leading-[18px] lg:text-[18px] lg:leading-[20px] font-sans font-semibold transition-all duration-300;
}

.card-inner {
  @apply w-full h-1/3 p-2 lg:px-[15px] lg:py-4;
}

.blog-card-inner {
  @apply w-full h-1/3 p-2 lg:px-[15px];
}

.product-card-sm {
  @apply bg-white shadow-md w-[calc((100%-1rem)/2)] lg:w-[calc((100%-2*1.5rem)/3)] lg:w-[calc((100%-2*1.5rem)/4)] h-[300px] lg:h-[293px] lg:h-[353px] rounded-[8px] flex flex-col relative transition-all duration-300 text-center flex-none;
}

.product-card-sm-image {
  @apply w-full h-2/3 rounded-t-[8px] transition-all duration-300 object-cover;
}

.product-card-sm-inner {
  @apply w-full h-1/3 p-2 lg:px-[15px] lg:py-4 grid grid-rows-2 items-start gap-4 pb-4;
}

.product-card-sm-title {
  @apply card-title text-left;
}

.product-card-sm-price-container {
  @apply flex flex-row justify-between items-center;
}

.product-card-sm-button {
  @apply p-3 rounded-md z-10;
}

.product-card-sm-price {
  @apply text-[16px] lg:text-[20px] lg:leading-[26px] font-sans font-bold;
}

.product-card-inner {
  @apply px-3 lg:px-4 rounded-tr-[8px] lg:rounded-none flex flex-col lg:flex-row items-start gap-1 lg:gap-2 lg:gap-4 justify-between h-full lg:w-full lg:h-1/3 py-2 lg:py-4 col-span-2;
}

.product-card-title {
  @apply card-title lg:text-[22px] lg:leading-[28px] overflow-hidden overflow-ellipsis whitespace-pre-wrap break-words;
}

.blog-card-date {
  @apply text-[10px] lg:text-[14px] lg:leading-[21px] mb-[5px];
}

.blog-card-button-holder {
  @apply flex justify-center;
}

.card-category {
  @apply w-fit px-3 py-[2px] lg:py-1 lg:px-[26px] absolute text-white font-sans font-bold text-[10px] lg:text-[12px] flex justify-center items-center rounded-[10px] top-[10px] lg:top-[15px] left-[6px] lg:left-[15px];
}

.product-card-inner-left,
.product-card-inner-right {
  @apply h-fit flex flex-col gap-[5px] lg:justify-between;
}

.product-card-inner-right {
  @apply lg:min-w-max lg:h-full items-end;
}

.product-card-inner-right .button-md {
  @apply mb-0;
}

.product-card-description {
  @apply text-[12px] lg:text-[14px] leading-[16.5px] lg:leading-[21px];
}

.product-card-price {
  @apply text-[18px] lg:text-[28px] lg:leading-[28px] font-sans font-bold;
}

.card-button {
  @apply rounded-[8px] text-white px-3 py-[6px] lg:py-[11px] min-w-max text-[12px] lg:text-[18px];
}

.featured-cards {
  @apply w-full lg:w-[1140px] flex flex-row flex-nowrap gap-4 overflow-x-auto pb-4 pr-10 lg:pr-16;
}

.featured-product-cards {
  @apply w-full lg:w-[1140px] flex flex-row flex-nowrap gap-4 overflow-x-auto pb-4;
}

.card-section {
  @apply w-full flex flex-wrap justify-center gap-4 h-fit;
}

.card-section-button-container {
  @apply h-fit w-full flex flex-col items-center justify-center;
}

.card-section-button {
  @apply rounded-[8px] text-white px-8 py-[6px] lg:py-[11px] min-w-max text-[12px] lg:text-[18px];
}

.card-section-title-container {
  @apply w-full h-fit flex items-center justify-center;
}

.card-section-title {
  @apply text-[32px] lg:text-[42px] leading-[42px] lg:leading-[54.6px] font-sans font-bold text-center;
}

.card-section-container {
  @apply w-full h-fit flex flex-col gap-6 lg:gap-8 items-center;
}

.product-card-section {
  @apply w-full gap-4 h-fit flex flex-wrap justify-center;
}
