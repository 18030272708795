@tailwind base;
@tailwind components;
@tailwind utilities;

/* -------------------- Footer -------------------- */
.footer-inner {
  @apply w-[full] h-[fit] text-white py-[25px] lg:py-[50px] text-[14px] lg:text-[16px] flex items-center justify-center;
}
.footer-container {
  @apply lg:w-[1140px] w-full h-fit justify-start flex flex-wrap lg:flex-nowrap gap-y-8 gap-x-4 lg:gap-x-[80px] px-[15px] lg:px-0;
}
.footer-contacts {
  @apply w-3/5 lg:w-fit  break-words flex flex-col gap-[20px] lg:gap-[36px];
}
.footer-logo {
  @apply h-[70px] max-w-fit;
}
.footer-contacts-holder {
  @apply flex flex-col gap-3;
}
.contact-holder {
  @apply flex flex-row gap-2;
}
.footer-navigation{
  @apply w-1/3 lg:w-fit flex flex-col gap-[20px] lg:gap-[36px];
}
.footer-navigation-holder {
  @apply flex flex-col gap-[10px];
}
.footer-newsletter {
  @apply w-full;
}

/* -------------------- Newsletter -------------------- */
.newsletter-form {
  @apply flex flex-col gap-4;
}
.newsletter-form-inner {
  @apply w-full lg:w-full relative flex flex-col gap-[10px];
}
.newsletter-form-input {
  @apply w-full h-[50px] rounded-[8px] p-3 text-[#333] outline-none;
}
.newsletter-form-submit-button {
  @apply w-[80px] px-2 h-[50px] rounded-[7px] absolute right-0 bottom-0 border-2 border-[#fff] font-bold lg:text-[22px] text-[20px] text-white;
}
.newsletter-form-terms {
  @apply flex flex-col gap-2 items-start flex-nowrap;
}
.newsletter-form-terms-checkbox-required {
  @apply w-5 h-5 flex-shrink-0 mt-1;
}
.newsletter-form-terms-holder {
  @apply flex flex-row gap-2;
}

/* -------------------- Font styles -------------------- */
.h1-title {
  @apply font-sans font-bold text-[30px] lg:text-[50px] leading-[39px] lg:leading-[59px] mb-[15px] lg:mb-[30px];
}
.subtitle {
  @apply font-sans font-normal text-[24px] lg:text-[34px] leading-[34px] lg:leading-[44px] mb-[15px] lg:mb-[30px];
}
.h2-title {
  @apply font-sans font-bold text-[32px] lg:text-[42px] leading-[43px] lg:leading-[55px] mb-[15px] lg:mb-[30px];
}
.h3-title {
  @apply font-sans font-bold text-[24px] lg:text-[34px] leading-[34px] lg:leading-[44px] mb-[10px] lg:mb-[15px];
}
.h4-title {
  @apply font-sans font-bold text-[16px] lg:text-[22px] leading-[27px] lg:leading-[33px] mb-[10px] lg:mb-[15px];
}
.h5-title {
  @apply font-sans font-bold text-[14px] lg:text-[18px] leading-[20px] lg:leading-[24px] mb-[5px] lg:mb-[10px];
}
.large-paragraph {
  @apply font-sans font-normal text-[16px] lg:text-[18px] leading-[25px] lg:leading-[29px] mb-[5px] lg:mb-[10px];
}
.normal-paragraph {
  @apply font-sans font-normal text-[14px] lg:text-[16px] leading-[21px] lg:leading-[24px] mb-[5px] lg:mb-[10px];
}
.small-paragraph {
  @apply font-sans font-normal text-[12px] lg:text-[14px] leading-[19px] lg:leading-[21px] mb-[5px] lg:mb-[10px];
}

/* -------------------- Copyright Text -------------------- */
.copyright-text {
  @apply w-full h-fit py-[15px] flex items-center justify-center;
}
.copyright-text-inner {
  @apply mx-[15px] lg:mx-[30px] lg:w-[1140px] w-full text-[14px] lg:text-[16px];
}
