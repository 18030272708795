@tailwind base;
@tailwind components;
@tailwind utilities;

.input-label {
  @apply absolute -top-4 -left-2 px-2 text-gray-500 transition-all transform -translate-y-1/2 pointer-events-none;
}


.input-container {
  @apply relative w-full text-sm lg:text-lg;
}

.input {
  @apply border-2 border-gray-400 p-2 w-full appearance-none bg-white;
}

.input-validation-error {
  @apply text-red-700 text-[14px];
}
