@tailwind base;
@tailwind components;
@tailwind utilities;

/* ------------------ Portable Text ------------------ */
.portable-text {
  @apply text-base w-full;
}

/* ------------------ H2 ------------------ */

.portable-text h2 {
  @apply font-bold leading-snug text-3xl lg:text-4xl mb-5 transition-all duration-300;
}

/* ------------------ H3 ------------------ */

.portable-text h3 {
  @apply font-bold leading-snug text-xl lg:text-2xl mb-3.5 transition-all duration-300;
}

/* ------------------ H4 ------------------ */

.portable-text h4 {
  @apply font-bold leading-snug text-lg lg:text-xl mb-2.5 transition-all duration-300;
}

/* ------------------ H5 ------------------ */

.portable-text h5 {
  @apply font-bold leading-snug text-base lg:text-lg mb-2.5 transition-all duration-300;
}

/* ------------------ Paragraph ------------------ */

.portable-text p {
  @apply mb-2;
}

/* ------------------ Quote ------------------ */

.portable-text blockquote {
  @apply text-lg italic font-medium mb-2 pl-3 border-l-4 border-[#C0D7EF];
}

/* ------------------ Link ------------------ */

.portable-text a {
  @apply text-[#237F7F] mb-2;
}

/* ------------------ Bullet list ------------------ */

.portable-text ul {
  @apply list-disc list-inside pl-4 mb-2;
}

/* ------------------ Numbered list ------------------ */

.portable-text ol {
  @apply list-decimal list-inside pl-4 mb-2;
}
