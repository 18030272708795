@tailwind base;
@tailwind components;
@tailwind utilities;

.alert-popup {
  @apply fixed inset-0 z-50 flex items-center justify-center;
}

.alert-popup-inner {
  @apply bg-white p-5 rounded-lg shadow-xl w-1/4 z-50 m-auto;
}

.alert-title {
  @apply text-xl font-bold mb-4;
}

.alert-message {
  @apply mb-4;
}

.alert-buttons {
  @apply px-4 py-2 rounded-lg bg-transparent border-2;
}

.alert-backdrop {
  @apply fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-30;
}